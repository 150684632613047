exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-hypnotherapy-via-zoom-tsx": () => import("./../../../src/pages/blog/hypnotherapy-via-zoom.tsx" /* webpackChunkName: "component---src-pages-blog-hypnotherapy-via-zoom-tsx" */),
  "component---src-pages-blog-what-is-hypnotherapy-tsx": () => import("./../../../src/pages/blog/what-is-hypnotherapy.tsx" /* webpackChunkName: "component---src-pages-blog-what-is-hypnotherapy-tsx" */),
  "component---src-pages-blog-why-choose-hypnotherapy-tsx": () => import("./../../../src/pages/blog/why-choose-hypnotherapy.tsx" /* webpackChunkName: "component---src-pages-blog-why-choose-hypnotherapy-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

